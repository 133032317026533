import React, { useEffect, useState } from "react";
import { Button, Flex, Modal, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./styles.css";
import TableComponent from "../other/TableComponent";
import MeetingRoomPopup from "./MeetingRoomPopup";
import { clientsApi, meetingRoomApis } from "../../api";
import DeletePopup from "./DeleteMeetingPopup";
import DeleteMeetingRoomPopup from "./DeleteMeetingRoomPopup";

const data = [
  {
    key: 1,
    meetingRoomName: "Room 1",
    place: "Banglore",
    seatingCapacity: "20",
    floor: "1",
    isProjector: false,
    isDisplayScreen: true,
    isApproved: true,
    meetingRoomLink: "Person 1",
  },
  {
    key: 2,
    meetingRoomName: "Room 2",
    client: "Client 2",
    place: "Jodhpur",
    seatingCapacity: "10",
    floor: "1",
    isProjector: true,
    isDisplayScreen: false,
    meetingRoomLink: "Person 2",
    lastUpdatedOn: "30/04/22",
  },
];

const Sow = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [allClientDetails, setAllClientDetails] = useState([]);
  const [cancelled, setCancelled] = useState(false);

  const showModal = (key) => {
    setSelectedKey(key);
    setIsModalOpen(true);
  };
  const showDeleteModal = (key) => {
    setSelectedKey(key);
    setIsDeleteModalOpen(true);
  };
  const handlePopupCancel = (func) => {
    setSelectedKey(null);
    setCancelled(true);
    setIsModalOpen(false);
  };
  const handleDeletePopupCancel = () => {
    setSelectedKey(null);
    setIsDeleteModalOpen(false);
  };

  const getTitle = () => {
    return selectedKey ? selectedKey : "Add Meeting Room";
  };

  useEffect(() => {
    meetingRoomApis
      .meetingRoomAllData()
      .then((res) => {
        setAllClientDetails(res?.data);
        console.log("res?.data", res?.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [isModalOpen || isDeleteModalOpen]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 130,
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      width: 110,
    },
    {
      title: "Seating Capacity",
      dataIndex: "seatingCapacity",
      width: 125,
      key: "seatingCapacity",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      width: 170,
    },
    {
      title: "Projector Availability",
      dataIndex: "isProjector",
      key: "isProjector",
      width: 200,
      render: (_, { isProjector }) =>
        isProjector ? (
          <Tag color="#a0d911">Yes</Tag>
        ) : (
          <Tag color="#f5222d">No</Tag>
        ),
    },
    {
      title: "Display Screen Availability",
      dataIndex: "isDisplayScreen",
      key: "isDisplayScreen",
      width: 150,
      render: (_, { isDisplayScreen }) =>
        isDisplayScreen ? (
          <Tag color="#a0d911">Yes</Tag>
        ) : (
          <Tag color="#f5222d">No</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 140,
      fixed: "right",
      render: (_, record) => (
        <>
          <Button onClick={() => showModal(record.mrId)} type="link">
            <EditOutlined />
          </Button>{" "}
          <Modal
            title={'Edit Meeting Room'}
            visible={isModalOpen && selectedKey === record?.mrId}
            onCancel={handlePopupCancel}
            footer={null}
            style={{marginTop:'-5%'}}

          >
            <MeetingRoomPopup
              hide={setIsModalOpen}
              buttonText={"Update"}
              meetingRoomData={record}
              isEditMode = {true}
            />
          </Modal>
          <Button style={{color : "red", margin : -10}} onClick={() => showDeleteModal(record.mrId)} type="link">
            <DeleteOutlined />
          </Button>{" "}
          <Modal
            // title={getTitle()}
            visible={isDeleteModalOpen && selectedKey === record?.mrId}
            onCancel={handleDeletePopupCancel}
            footer={null}
          >
            <DeleteMeetingRoomPopup
              hide={setIsDeleteModalOpen}
              buttonText={"Delete"}
              clientId={record.mrId}
              meetingRoomData={record}
            />
          </Modal>
        </>
      ),
    },
  ];

  return (
    <>
      <Flex justify="space-between">
        <h2>Meeting Rooms</h2>
        <Button onClick={() => showModal(null)} type="primary">
          Add Meeting Room
        </Button>{" "}
        <Modal
          title={getTitle()}
          visible={isModalOpen && !selectedKey}
          onCancel={handlePopupCancel}
          footer={null}
          style={{marginTop:'-5%'}}
        >
          <MeetingRoomPopup
            hide={setIsModalOpen}
            buttonText={"Save"}
            setCancelled={setCancelled}
            cancelled={cancelled}
            isEditMode = {false}
          />
        </Modal>
      </Flex>
      <TableComponent
        data={allClientDetails}
        columns={columns}
        scrollX={1400}
      />
    </>
  );
};

export default Sow;
