import React from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { PlusOutlined, CalendarOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Flex } from "antd";
import MeetingRoom from "./components/projectPages/MeetingRoom";
import Cr from "./components/projectPages/Calendar";
import Notification from "./components/headerItems/Notification";
import User from "./components/headerItems/User";
import Rb from "./components/projectPages/Rb";
import { Divider } from "antd";
import {GetRole} from './userRole';

const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const role = GetRole();

const navMenuItems = [
  // { key: 'Projects', label: 'Projects', path: '/projects' },
  {
    key: "CR",
    icon: <CalendarOutlined />,
    label: "My meetings",
    path: "/meetings",
  },
  {
    key: "RB",
    icon: <CalendarOutlined />,
    label: "Book new meeting",
    path: "/meeting-room-booking",
  },
];

role === "admin" &&
  navMenuItems.unshift({
    key: "MR",
    icon: <PlusOutlined />,
    label: "Add meeting room",
    path: "/meeting-room",
  });

function App() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const location = useLocation();

  return (
    <Layout style={{}}>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Flex>
          <img
            src="https://spanidea.com/in/wp-content/uploads/2022/08/png-white-logo-300x53.png"
            alt="logo"
            style={{ height: "30px" }}
          />
        </Flex>
        <Flex justify="center">
          <span
            style={{
              color: "white",
              fontSize: "25px",
              textAlign: "center",
              fontWeight: "600",
              marginRight: 40,
            }}
          >
            Conference Room Booking
          </span>
        </Flex>
        <Flex gap="20px">
          <Notification />
          <User />
        </Flex>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={["/meetings"]}
            style={{
              height: "100%",
              borderRight: 0,
              marginTop: "1rem",
            }}
          >
            {navMenuItems.map((menuItem, index) => {
              return (
                <>
                  <Menu.Item key={menuItem.path} icon={menuItem.icon}>
                    <Link to={menuItem.path}>{menuItem.label}</Link>
                  </Menu.Item>
                  {index < navMenuItems.length - 1 && (
                    <Divider style={{ margin: 0 }} />
                  )}
                </>
              );
            })}
          </Menu>
        </Sider>
        <Layout className="contentWrapper">
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Cr />} />
              {/* <Route path="/projects" element={<Projects />} /> */}
              {role === "admin" && (
                <Route path="/meeting-room" element={<MeetingRoom />} />
              )}
              <Route path="/meetings" element={<Cr />} />
              <Route path="/meeting-room-booking" element={<Rb />} />
            </Routes>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
      <Toaster />
    </BrowserRouter>
  );
}

export default AppWrapper;
