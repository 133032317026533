import React from "react";
import "./styles.css";
import { Flex } from "antd";
import dayjs from "dayjs";

const MeetingInformationPopup = ({ meetingData, invitees }) => {
  return (
    <div
      id="calendar-popup"
      className="popupDiv"
      style={{ height: "63vh", overflow: "auto" }}
    >
      {meetingData.empCode !== meetingData.createdBy && (
        <div className="not-created">*You did not create this meeting</div>
      )}
      <Flex vertical>
        <div className="meeting-title-heading">Meeting Room</div>
        <div className="meeting-title">{meetingData.meetingRoomName}</div>
      </Flex>
      <Flex vertical>
        <div className="meeting-title-heading">Subject</div>
        <div className="meeting-title">{meetingData.title}</div>
      </Flex>
      <Flex vertical>
        <div className="meeting-title-heading">Agenda</div>
        <div
          className="meeting-title"
          dangerouslySetInnerHTML={{ __html: meetingData.agenda }}
        />
      </Flex>
      {meetingData.meetingRoomLink && (<Flex vertical>
        <div className="meeting-title-heading">Link</div>
        <div className="meeting-title">{meetingData.meetingRoomLink}</div>
      </Flex>
      )}
      <Flex gap={"0.8rem"} vertical justify="center">
        <Flex gap="0.5rem">
          <div className="meeting-title-heading meeting-time">Starts at </div>
          <div className="meeting-title">
            {dayjs(meetingData.start).format("h:mm A")}
          </div>
        </Flex>
        <Flex gap="0.5rem">
          <div className="meeting-title-heading meeting-time">Ends at </div>
          <div className="meeting-title">
            {dayjs(meetingData.end).format("h:mm A")}
          </div>
        </Flex>
      </Flex>
      <Flex vertical>
        <p className="meeting-title-heading">Invitees</p>
        <Flex gap={"0.5rem"}>
          <ul>
            {invitees.map((invitee, ind) => (
              <li className="invitees-label">{invitee.label}</li>
            ))}
          </ul>
        </Flex>
      </Flex>
    </div>
  );
};

export default MeetingInformationPopup;
