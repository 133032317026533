import React, { useState } from "react";
import { Button, Flex, Modal } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { meetingRoomApis } from "../../api";
import toast from "react-hot-toast";
const DeleteMeetingRoomPopup = ({ hide, clientId,meetingRoomData }) => {
  
  const handleOk = async () => {
    const toastId=toast.loading("Loading...")
    try {
      await meetingRoomApis.deleteMeetingRoom(clientId);
      hide();
      toast.success("Meeting room deleted")
    } catch (err) {
      console.log("error in deleting meeting room api ",err)
      toast.error("Error in deleting meeting room")
    }
    toast.dismiss(toastId)
  };
  return (
    <>
      <Flex vertical gap="" style={{ width: "100%" }}>
        <div className="delete-btn-f1">
          <WarningTwoTone twoToneColor={"#bb2124"} />
          {`  `}Are you sure you want to delete {meetingRoomData.name}?
        </div>
        <div className="deletePopup">
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>
          <Button type="" onClick={() => hide()}>
            Cancel
          </Button>
        </div>
      </Flex>
    </>
  );
};
export default DeleteMeetingRoomPopup;
