import React, { useState } from "react";
import { Button, Flex, Modal } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { clientsApi, roomBookingApi } from "../../api";
import toast from "react-hot-toast";
const DeleteMeetingPopup = ({
  modalData,
  handlePopupCancel,
  setConfirmationModal,
}) => {
  console.log("modal data in delete popup ", modalData);
  const deleteSingleMeeting = async () => {
    const toastId = toast.loading("Loading...");
    try {
      const resp = await roomBookingApi.deleteRoomTime({
        rtId: modalData.rtId,
        empCode: modalData.empCode,
      });
      console.log("printing delete room time api response ", resp);
      setConfirmationModal(null);
      handlePopupCancel();
      toast.success("Meeting Deleted");
    } catch (err) {
      console.log("error in delete room time api ", err);
      setConfirmationModal(null);
      handlePopupCancel();
      toast.error("Error in deleting meeting");
    }
    toast.dismiss(toastId);
  };

  const deleteAllMeetings = async () => {
    const toastId = toast.loading("Loading...");
    try {
      const resp = await roomBookingApi.deleteRoomBooking({
        rbId: modalData.rbId,
        empCode: modalData.empCode,
      });
      console.log("printing delete room booking api response ", resp);
      // handlePopupCancel();
      setConfirmationModal(null);
      handlePopupCancel();
      toast.success("All Meetings Deleted");
    } catch (err) {
      console.log("error in delete room booking api ", err);
      setConfirmationModal(null);
      handlePopupCancel();
      toast.error("Error in deleting all meetings");
    }
    toast.dismiss(toastId);
  };

  return (
    <div>
      <h2 id="delete-">
        Do you want to delete all occurences of this meeting?
      </h2>
      <Flex justify="end" gap={"0.8rem"}>
        <Button onClick={deleteSingleMeeting}>Just this meeting</Button>
        <Button onClick={deleteAllMeetings} type="primary">Yes</Button>
      </Flex>
    </div>
  );
};
export default DeleteMeetingPopup;
