import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Flex,
  Modal,
  Input,
  Select,
  DatePicker,
  Form,
  Switch,
} from "antd";
import "./styles.css";
import { clientsApi, meetingRoomApis } from "../../api";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import toast from "react-hot-toast";

// const validate = (values) => {
//   const errors = {};
//   if (!values.placeId) {
//     errors.placeId = "error";
//   }
//   if (!values.name) {
//     errors.name = "error";
//   }
//   if (!values.isProjector) {
//     errors.isProjector = "error";
//   }
//   if (!values.isDisplayScreen) {
//     errors.isDisplayScreen = "error";
//   }
//   if (!values.meetingRoomLink) {
//     errors.meetingRoomLink = "error";
//   }
//   if (!values.floor) {
//     errors.floor = "error";
//   }
//   if (!values.seatingCapacity) {
//     errors.seatingCapacity = "error";
//   }
//   return errors;
// };

const MeetingRoomPopup = ({ hide, buttonText, meetingRoomData, cancelled , isEditMode}) => {
  const [cityList, setCityList] = useState([]);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(true);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [form] = useForm();
  console.log("printing cancelled ", cancelled);
  if (cancelled && !meetingRoomData) {
    console.log("in cancelling if condition");
    form.resetFields();
  }

  const onSpanideaAuthorizorSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleSubmit = async (values) => {
    const toastId = toast.loading("Loading...");
    try {
      const isDuplicateRoom = meetingRooms.some(
        (room) => room.name === values.name && room.placeId === values.placeId && room.mrId !== (meetingRoomData?.mrId ?? null)
      );

      if (isDuplicateRoom) {
        toast.dismiss(toastId);
        toast.error("Meeting room with the same name already exists in this place.");
        return;
      }

      const meetingRoomPayload = {
        name: values.name,
        placeId: values.placeId,
        floor: values.floor,
        seatingCapacity: values.seatingCapacity,
        isProjector: values.isProjector,
        isDisplayScreen: values.isDisplayScreen,
      };
      if (meetingRoomData) {
        meetingRoomPayload.placeId = values.placeId.value;
        const resp = await meetingRoomApis.updateMeetingRoom(
          meetingRoomData.mrId,
          meetingRoomPayload
        );
      } else {
        const resp = await meetingRoomApis.createMeetingRoom(
          meetingRoomPayload
        );
        form.resetFields();
      }
      hide();
      toast.success(
        meetingRoomData ? "Meeting room updated " : "Meeting room created"
      );
    } catch (err) {
      toast.error(
        `Error in ${meetingRoomData ? "updating" : "creating"} meeting room`
      );
      console.log(
        `error in ${meetingRoomData ? "updating" : "creating"} meeting room `,
        err
      );
    }
    toast.dismiss(toastId);
  };

  const handleChange = (e) => {
    if (meetingRoomData) {
      setIsUpdateButtonDisable(false);
    }
  };

  // const formik = useFormik({
  //   initialValues,
  //   validate,
  //   enableReinitialize: true,
  //   onSubmit: async (values, { resetForm }) => {

  //     try {
  //       const errors = validate(values);
  //       if (Object.keys(errors).length === 0) {
  //         const postData = {
  //           name: values.name,
  //           placeId: values.placeId,
  //           floor: values.floor,
  //           seatingCapacity: values.seatingCapacity,
  //           isProjector: values.isProjector,
  //           isDisplayScreen: values.isDisplayScreen,
  //           meetingRoomLink: values.meetingRoomLink,
  //         };
  //         if (mrId) {
  //           clientsApi
  //             .updateClientData(mrId, postData)
  //             .then((res) => {
  //               resetForm();
  //               hide();
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //         } else {
  //           clientsApi
  //             .addClientData(postData)
  //             .then((res) => {
  //               hide();
  //               resetForm();
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //         }
  //       } else {
  //         console.error("Validation Error:", errors);
  //       }
  //     } catch (error) {
  //       console.error("API Error:", error);
  //     }
  //   },
  // });

  useLayoutEffect(() => {
    if (meetingRoomData) {
      if (!isUpdateButtonDisable) {
        setIsUpdateButtonDisable(true);
      }
      form.setFieldsValue({
        name: meetingRoomData.name,
        placeId: [
          { value: meetingRoomData.placeId, name: meetingRoomData.place },
        ],
        floor: meetingRoomData.floor,
        seatingCapacity: meetingRoomData.seatingCapacity,
        isProjector: meetingRoomData.isProjector,
        isDisplayScreen: meetingRoomData.isDisplayScreen,
        meetingRoomLink: meetingRoomData.meetingRoomLink,
      });
    }
  }, [meetingRoomData]);

  useEffect(() => {
    clientsApi
      .getCityList()
      .then((res) => {
        setCityList(res.data);
      })
      .catch((error) => console.error("Error fetching data:", error));

      meetingRoomApis.meetingRoomAllData().then((res) => {
        setMeetingRooms(res.data);
      }).catch((error) => console.error("Error fetching meeting rooms:", error));
  }, []);

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <div
          className="popupDiv  meetingRoomPopUp"
          style={{ height: "55vh", overflow: "auto" }}
        >
          <Form.Item name={"name"} label="Name" rules={[{ required: true }]}>
            <Input
              name="name"
              id="name"
              size="large"
              placeholder="Enter meeting room's name"
              onChange={handleChange}
              maxLength={30}
            />
          </Form.Item>

          <Form.Item
            name={"seatingCapacity"}
            label="Seating Capacity"
            rules={[{ required: true }]}
          >
            <Input
              type="number"
              size="large"
              min={1}
              name="seatingCapacity"
              id="seatingCapacity"
              placeholder="Enter seating capacity"
              onChange={handleChange}
              max={100}
            />
          </Form.Item>

          <Form.Item
            name={"placeId"}
            rules={[{ required: true }]}
            label="Place"
          >
            <Select
              showSearch
              placeholder="Select place"
              id="placeId"
              size="large"
              optionFilterProp="children"
              onSearch={onSpanideaAuthorizorSearch}
              filterOption={filterOption}
              style={{ width: "100%" }}
              options={cityList.map((authorizer) => ({
                value: authorizer.id,
                label: authorizer.name,
              }))}
              disabled={isEditMode}
              onChange = {handleChange}
            />
          </Form.Item>

          <Form.Item name={"floor"} rules={[{ required: true }]} label="Floor">
            <Input
              type="number"
              size="large"
              name="floor"
              id="floor"
              min={-2}
              max={20}
              placeholder="Enter floor"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            name={"isProjector"}
            valuePropName="checked"
            label="Projector"
            initialValue={true}
          >
            <Switch
              defaultChecked
              size="large"
              name="isProjector"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            name={"isDisplayScreen"}
            validateDebounce={"checked"}
            label="Display Screen"
            initialValue={true}
          >
            <Switch
              defaultChecked
              size="large"
              name="isDisplayScreen"
              onChange={handleChange}
            />
          </Form.Item>
        </div>

        <Flex justify="end" style={{marginTop:'5%'}}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={meetingRoomData && isUpdateButtonDisable}
          >
            {buttonText}
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default MeetingRoomPopup;
