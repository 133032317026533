import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const getToken = Cookies?.get('token');
// const getToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbXBsb3llZU5vIjoiU1BBTi1CTFItMTkyNCIsIm5hbWUiOiJSYXZpbmRyYSBQcmFqYXBhdCIsImlzQWRtaW4iOmZhbHNlLCJlbWFpbCI6InJhdmluZHJhcEBzcGFuaWRlYS5jb20iLCJwb3J0YWxBY2Nlc3MiOlt7InJvbGVOYW1lIjoiVXBkYXRlIiwicG9ydGFsTmFtZSI6IkNNUyJ9LHsicm9sZU5hbWUiOiJWaWV3IiwicG9ydGFsTmFtZSI6Ik1FRVRJTkcgUk9PTSJ9LHsicm9sZU5hbWUiOiJBZG1pbiIsInBvcnRhbE5hbWUiOiJUSU1FIFNIRUVUIn1dLCJ0aW1lc2hlZXREZWxpdmVyeU1hbmFnZXIiOmZhbHNlLCJpYXQiOjE3MjEyODAxMjQsImV4cCI6MTcyMTM2NjUyNH0.2qc7Glt-Cdhg0Y1rfk9rraB_gQq2YjuIbdxSzPqhNeg"
console.log("getToken",getToken)

let employeeId = "";
let roleName = "";

try {
  const decodedToken = jwtDecode(
    getToken,
    process.env.REACT_APP_JWT_SECRET_KEY
  );
  console.log("decodedToken", decodedToken);
  employeeId = decodedToken.employeeNo;
  const listOfPortalName = decodedToken.portalAccess;
  if(decodedToken?.isAdmin){
    roleName="Admin"
  }
  else if (
    !decodedToken?.isAdmin &&
    !(listOfPortalName.some(role => role.portalName === 'MEETING ROOM'))
  ) {
    window.location.href = "https://stagingapps.spanidea.com/login";
  } else {
    roleName = listOfPortalName.find(role => role.portalName === 'MEETING ROOM').roleName;
  }
} catch (error) {
  window.location.href = "https://stagingapps.spanidea.com/login";
}

const GetRole = () => {
  if (roleName === "Admin") {
    return "admin";
  }
  if (roleName === "View") {
    return "";
  }
};


export {GetRole, employeeId};

