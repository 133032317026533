// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-header {
    font-size: 14px;
    height: 36px;
    font-weight: bold;
}

.rbc-header th {
    display: block;
}

.rbc-allday-cell {
    display: none;
}

.rbc-selected-cell,
.rbc-day-slot,
.rbc-timeslot-group,
.rbc-header {
  background-color: transparent !important;
}

.week-view-container .rbc-event{
    width: 112% !important;
}

.rbc-event-label{
    font-size: 10px;
    font-weight: 600;
}

.month-view-container .rbc-event{
    width: 100% !important;
}

.month-view-container .rbc-today{
    border: 1px solid #547ea0
}
`, "",{"version":3,"sources":["webpack://./src/components/projectPages/WeeklyCalendar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;;;;EAIE,wCAAwC;AAC1C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI;AACJ","sourcesContent":[".rbc-header {\n    font-size: 14px;\n    height: 36px;\n    font-weight: bold;\n}\n\n.rbc-header th {\n    display: block;\n}\n\n.rbc-allday-cell {\n    display: none;\n}\n\n.rbc-selected-cell,\n.rbc-day-slot,\n.rbc-timeslot-group,\n.rbc-header {\n  background-color: transparent !important;\n}\n\n.week-view-container .rbc-event{\n    width: 112% !important;\n}\n\n.rbc-event-label{\n    font-size: 10px;\n    font-weight: 600;\n}\n\n.month-view-container .rbc-event{\n    width: 100% !important;\n}\n\n.month-view-container .rbc-today{\n    border: 1px solid #547ea0\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
