import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCity: null,
  selectedMeetingRoom: null,
};

const meetingRoomSlice = createSlice({
  name: 'meetingRoom',
  initialState,
  reducers: {
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
      state.selectedMeetingRoom = null; 
    },
    setSelectedMeetingRoom: (state, action) => {
      state.selectedMeetingRoom = action.payload;
    },
  },
});

// Export actions and reducer
export const { setSelectedCity, setSelectedMeetingRoom } = meetingRoomSlice.actions;
export default meetingRoomSlice.reducer;
