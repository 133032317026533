import React from "react";
import moment from "moment";

const SlotBookingPopup = ({ modalData }) => {
  console.log("printing modal data in slot booking modal ", modalData);
  return (
    <div>
      {modalData?.slotAvailabilityPercentage > 25 ? (
        <div>
          {modalData?.unavailableDates?.length === 0 ? (
            <h1> Meeting room not available</h1>
          ) : (
            <h1>
              {" "}
              Meeting room not available for {
                modalData?.noOfUnavailableSlots
              }{" "}
              {modalData?.noOfUnavailableSlots >= 1 ? "days" : "day"}
            </h1>
          )}

          <p>Please select a different meeting room or a different slot</p>
        </div>
      ) : (
        <div>
          <h1>
            {modalData?.noOfUnavailableSlots > 0
              ? "Meeting room not available for"
              : "All slots available"}
          </h1>
          {modalData?.unavailableDates?.length > 0 && (
            <div>
              {modalData?.unavailableDates?.map((date, ind) => {
                return <p key={ind}>{date}</p>;
              })}
            </div>
          )}

          <p>
            {modalData?.noOfUnavailableSlots > 0
              ? "Do you still want to book for remaining slots?"
              : "Do you want to book?"}
          </p>
        </div>
      )}
    </div>
  );
};

export default SlotBookingPopup;
